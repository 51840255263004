<template>
  <v-row>
    <v-dialog v-model="helpAsignment" persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Penugasan</h3>
        </v-toolbar>
        <div class="py-3 px-5">
          <h3>
            Pada menu ini peserta diberikan tugas. Dalam pengerjaan tugas
            peserta beracuan pada narasi yang disediakan. Lalu terdapat data
            kasus dan informasi pendukung yang berkaitan dengan narasi untuk
            mempermudah peserta dalam mengerjakan tugas.
          </h3>
          <section>
            <img
              src="../../../assets/img/tutorial/narasi-case.jpg"
              width="100%"
              class="mr-3"
              alt=""
            />
          </section>

          <div>
            <section class="mb-1">
              <h3>
                1. Narasi berisikan mengenai deskripsi lengkap dari kasus yang
                harus diselesaikan oleh peserta. Narasi dapat berfungsi sebagai
                alat bantu dalam memahami data kasus secara menyeluruh
              </h3>
            </section>
            <section>
              <h3>
                2. Merupakan kumpulan data berbentuk grafik yang berkaitan
                dengan narasi. Beberapa data kasus yang disediakan masih dalam
                kondisi terkunci, bila ingin melihat data kasus peserta perlu
                menggunakan poin mereka. Jika poin tidak mencukupi maka peserta
                diharuskan memainkan game. Grafik didalam data kasus yang
                ditampilkan dapat mengalami perubahan seiring berjalanya
                pelatihan sehingga peserta perlu melakukan pengecekan secara
                berkala. Data kasus ini digunakan peserta untuk bahan
                pertimbangan dalam mengerjakan tugas.
              </h3>
            </section>
          </div>
        </div>
        <div class="py-3 px-5">
          <section>
            <img
              src="../../../assets/img/tutorial/tugas-news.jpg"
              width="100%"
              class="mr-3"
              alt=""
            />
          </section>
          <div>
            <section class="mb-1">
              <h3>
                3. Pada bagian ini peserta dapat melihat informasi pendukung
                yang dapat digunakan dalam mengerjakan tugas. Informasi
                pendukung dapat dibuka menggunakan poin, apabila poin peserta
                tidak mencukupi peserta diharuskan memainkan game.
              </h3>
            </section>
            <section>
              <h3>
                4.Peserta dapat melihat tugas apa yang harus dikerjakan berdasar
                narasi, data kasus, dan informasi pendukung yang disediakan.
              </h3>
            </section>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')">tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "helpAsignment",
  props: ["helpAsignment"],
  data() {
    return {
      close: false,
      counting: null,
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
