<template>
  <div class="container-general">
    <v-btn class="indigo mb-1" dark @click="helpAsignment = true"><v-icon class="mr-1">mdi-help-circle-outline</v-icon> Bantuan</v-btn>
    <TutorAssignment v-bind:helpAsignment="helpAsignment" @close="close"/>
    <div class="temp-general">
      <section class="banner d-flex mb-3 indigo">
        <img src="../../assets/img/dataset.svg" height="100px" class="mr-3" />
        <div>
          <h1 class="white--text">Monitor Data Set</h1>
        </div>
      </section>
      <div class="d-flex">
        <v-card class="temp-narasi white" v-if="profile">
          <v-toolbar color="indigo" dense dark><h3>Narasi</h3></v-toolbar>
          <section class="pa-3">
            <p v-snip="9" v-if="fase == 'Individu'">
              <span
                v-html="profile.grup.pelatihan.modul.narasi_individu"
              ></span>
            </p>

            <p v-snip="9" v-if="fase == 'Kelompok'">
              <span
                v-html="profile.grup.pelatihan.modul.narasi_kelompok"
              ></span>
            </p>
          </section>
          <section class="wraper d-flex justify-center align-center">
            <v-btn color="indigo" dark @click="viewNaration()"
              >baca selengkapnya</v-btn
            >
          </section>
        </v-card>
        <v-card class="slides white">
          <v-toolbar color="indigo" dense dark><h3>Data Kasus</h3></v-toolbar>
          <section class="px-6">
            <carousel
              :navigationEnabled="true"
              :perPage="3"
              :paginationPadding="2"
              :perPageCustom="[
                [500, 1],
                [768, 2],
                [1024, 3],
              ]"
            >
              <slide v-for="casie in CasesLock" :key="casie.id">
                <v-card class="my-5 ml-3 pa-2" max-width="210" height="200px">
                  <div>
                    <line-chart :legend="false" height="100px" :data="chart" />
                  </div>
                  <v-card-text class="pa-1 indigo--text">
                    <div>
                      <h3>{{ casie.modulKasus.kasus }}</h3>
                    </div>
                  </v-card-text>

                  <v-card-actions class="pa-0">
                    <v-btn
                      color="orange"
                      text
                      @click="viewDataCase(casie.modulKasus)"
                    >
                      Lihat detail
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </slide>
              <slide v-for="(casie, idx) in CasesUnlock" :key="idx">
                <v-card
                  class="my-5 ml-3 pa-2 d-flex justify-center align-center"
                  max-width="210"
                  height="200px"
                >
                  <v-card-text class="pa-1 orange--text">
                    <div class="d-flex flex-column align-center">
                      <v-icon x-large color="orange">mdi-lock-alert</v-icon>
                      <h3>Data Kasus Terkunci</h3>
                      <v-btn
                        color="indigo"
                        dark
                        class="mt-2"
                        @click="
                          unlockCase(
                            casie,
                            `unlock_kasus_${casie.fase}${idx + 1}`
                          )
                        "
                        >Buka data kasus</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </slide>
            </carousel>
          </section>
        </v-card>
      </div>
      <DialogPlay
        v-bind:dialogPlay="dialogPlay"
        v-bind:goTo="goTo"
        v-on:false="dialogFalse"
      />
      <section class="d-flex">
        <v-card class="recent-news" v-if="isLocked">
          <div :class="isLocked.berita == 'locked' ? 'wraper' : '_nowrap'">
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-btn color="orange" dark @click="unlockNews">
                buka Informasi</v-btn
              >
            </div>
          </div>
          <v-toolbar color="indigo" dense dark
            ><h3>Informasi Pendukung</h3>
          </v-toolbar>
          <div class="pa-2">
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="itemNews in news"
                :key="itemNews.id"
                class="mb-2"
              >
                <v-expansion-panel-header class="leftborder">{{
                  itemNews.judul
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <section>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      :href="itemNews.link"
                    >
                      <p>{{ itemNews.link }}</p>
                    </a>
                  </section>
                  <section>
                    <p v-html="itemNews.isi_berita"></p>
                  </section>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card>
        <v-card class="objective">
          <v-toolbar color="indigo" dense dark><h3>Tugas</h3></v-toolbar>
          <section class="pa-2">
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="obj in Objective"
                :key="obj.id"
                class="mb-2"
              >
                <v-expansion-panel-header class="leftborder">{{
                  obj.judul
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p v-html="obj.keterangan"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </section>
        </v-card>
      </section>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { GET_USER } from "../../graphql/graphql";
import { mapState } from "vuex";
import DialogPlay from "../../components/_base/dialogPlay";
import moment from "moment";
import Swal from "sweetalert2";
import TutorAssignment from "../../components/Student/tutorial/tutorAssignment"
export default {
  name: "Assigment",
  apollo: {
    profile: {
      query: GET_USER,
    },
  },
  computed: {
    ...mapState(["countDay", "errMsg", "badge_case", "pathGame"]),
    isLocked() {
      let data = null;
      if (this.profile) {
        data = JSON.parse(this.profile.student.config);
      }
      return data;
    },
    news() {
      let data = [];
      if (this.profile) {
        data = this.profile.grup.pelatihan.modul.berita;
      }
      return data;
    },
    CasesLock() {
      let data = [];
      if (this.profile) {
        if (localStorage.getItem("fase") == "Individu") {
          data = this.profile.kasus_peserta.filter((el) => {
            return el.fase == "individu" && el.status == 1;
          });
        } else if (localStorage.getItem("fase") === "Kelompok") {
          data = this.profile.kasus_peserta.filter((el) => {
            return el.fase == "kelompok" && el.status == 1;
          });
        }
      }
      return data;
    },
    CasesUnlock() {
      let data = [];
      if (this.profile) {
        if (localStorage.getItem("fase") == "Individu") {
          data = this.profile.kasus_peserta.filter((el) => {
            return el.fase == "individu" && el.status == 0;
          });
        } else if (localStorage.getItem("fase") === "Kelompok") {
          data = this.profile.kasus_peserta.filter((el) => {
            return el.fase == "kelompok" && el.status == 0;
          });
        }
      }
      return data;
    },
    Objective() {
      let data = [];
      if (this.profile) {
        if (localStorage.getItem("fase") == "Individu") {
          data = this.profile.grup.pelatihan.modul.modul_tugas.filter((el) => {
            return el.fase == "INDIVIDU";
          });
        } else if (localStorage.getItem("fase") == "Kelompok") {
          data = this.profile.grup.pelatihan.modul.modul_tugas.filter((el) => {
            return el.fase == "KELOMPOK";
          });
        }
      }
      return data;
    },
  },
  data() {
    return {
      today: moment(),
      fase: localStorage.getItem("fase"),
      helpAsignment: false,
      goTo: "",
      chart: [
        {
          name: "Workout",
          data: {
            "2017-01-01": 0,
            "2017-01-02": 2,
            "2017-01-03": 0,
            "2017-01-04": 2,
            "2017-01-05": 0,
            "2017-01-06": 2,
          },
        },
        {
          name: "Call parents",
          data: {
            "2017-01-01": 2,
            "2017-01-02": 1,
            "2017-01-03": 2,
            "2017-01-04": 0,
            "2017-01-05": 1,
            "2017-01-06": 2,
          },
        },
      ],
      dialogPlay: false,
    };
  },
  components: {
    Carousel,
    Slide,
    DialogPlay,
    TutorAssignment,
  },
  methods: {
    viewNaration() {
      this.$router.push("narasi");
    },
    close() {
      this.helpAsignment = false
    },
    unlockCase(data, tipe) {
      const harga = data.harga;
      if (this.profile.student.poin >= data.harga) {
        Swal.fire({
          title: "Ingin membuka data kasus ini?",
          imageUrl: "https://dashboard.anargya.id/img/quest.png",
          text: "Poin Anda akan berkurang sebanyak " + data.harga,
          imageWidth: 100,
          imageHeight: 100,
          showCancelButton: true,
          confirmButtonColor: "#3F51B5",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("unlockCase", {
                kasus_id: data.modulKasus.id,
                fase: data.fase,
              })
              .then(() => {
                this.$apollo.queries.profile.refetch();
                console.log(harga);
                let point = Math.ceil(this.profile.student.poin - harga);
                console.log(point);
                Swal.fire({
                  imageUrl: "https://dashboard.anargya.id/img/unlock.png",
                  text: "Sisa poin Anda adalah " + point,
                  imageWidth: 100,
                  imageHeight: 100,
                  title: "Data kasus telah terbuka",
                  showConfirmButton: true,
                  confirmButtonColor: "#3F51B5",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    console.log(typeof this.badge_case.badge);
                    if (typeof this.badge_case.badge == "string") {
                      Swal.fire({
                        imageUrl: `${process.env.VUE_APP_GRAPHQL}/badges/${this.badge_case.badge}`,
                        imageHeight: 150,
                        title: "Selamat Anda mendapatkan lencana baru!",
                        showConfirmButton: true,
                        confirmButtonColor: "#3F51B5",
                        confirmButtonText: "Ok",
                      });
                    } else {
                      return;
                    }
                  }
                });
              })
              .catch(() => {
                Swal.fire({
                  icon: "error",
                  title: this.errMsg,
                });
              });
          }
        });
      } else {
        // localStorage.setItem('fromRoute', )
        this.goTo = Object.keys(this.pathGame)
          .filter((x) => {
            return x == tipe;
          })
          .reduce((obj, key) => {
            obj[key] = this.pathGame[key];
            return this.pathGame[key];
          }, {});
        this.dialogPlay = true;
      }
    },
    unlockNews() {
      if (this.profile.student.poin >= 300) {
        const id = localStorage.getItem("id");
        Swal.fire({
          title: "Ingin membuka Informasi pendukung?",
          imageUrl: "https://dashboard.anargya.id/img/quest.png",
          text: "Poin Anda akan berkurang sebanyak " + 300,
          imageWidth: 100,
          imageHeight: 100,
          showCancelButton: true,
          confirmButtonColor: "#3F51B5",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("unlockNews", { id: id }).then(() => {
                this.$apollo.queries.profile.refetch();
                let point = Math.ceil(this.profile.student.poin - 300);
                console.log(point);
                Swal.fire({
                  icon: "success",
                  title: "Informasi pendukung telah terbuka",
                  text: "Sisa poin Anda adalah " + point,
                  showConfirmButton: true,
                  confirmButtonColor: "#3F51B5",
                  confirmButtonText: "Ok",
                  // timer: 2000,
                });
              });
            }
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: this.errMsg,
              position: "top",
              toast: true,
            });
          });
      } else {
        this.goTo = this.pathGame.unlock_berita;
        this.dialogPlay = true;
      }
    },
    dialogFalse() {
      this.dialogPlay = false;
    },
    viewDataCase(data) {
      this.$store.commit("DATA_CASE", data);
      let day = parseInt(localStorage.getItem("day"));
      this.$store
        .dispatch("previewChart", {
          id: data.id,
          chart: data.chart,
          q: day,
        })
        .then(() => {
          this.$router.push(`/case/${data.id}`);
        });
    },
  },
};
</script>

<style>
.container-general {
  width: 100%;
  background: #f4f6f9;
  padding: 10px;
}
.banner {
  border-radius: 8px;
  padding: 10px;
}

.objective {
  width: 40%;
  height: max-content;
  margin: 10px;
}
.temp-narasi {
  position: relative;
  width: 20%;
  margin: 10px;
  overflow: hidden;
  height: max-content;
}
.recent-news {
  width: 60%;
  background: #ffffff;
  height: max-content;
  margin: 10px;
}
.slides {
  width: 75%;
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.leftborder {
  border-left: orange 3px solid;
}
.wraper {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  transition: 0.5s;
}
.wraper:hover {
  opacity: 1;
  transition: 0.5s;
}
._nowrap {
  display: none;
  overflow: hidden;
}
</style>
